import {api} from '../../../shared/services/backend-api'

const RESOURCE = 'slider'

const uploadSliderImages = (images) => {
  const payload = new FormData()
  images.forEach(({raw}) => {
    payload.append('photos[]', raw)
  })
  
  return api.post(`${RESOURCE}`, payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
}

const fetchSliderImages = () => {
  return api.get(`${RESOURCE}`)
}

const deleteSliderImage = (imageId) => {
  return api.delete(`slider-image/${imageId}`)
}

const updateSliderContent = (images, swapDelay) => {
  const photos = images.map((img, idx) => {
    const {photoId, unitId, title, description} = img
    
    return {
      photoId,
      unitId,
      description,
      title,
      orderNumber: idx
    }
  })
  
  const payload = {
    photos,
    durationTime: swapDelay
  }
  
  return api.put(`${RESOURCE}`, payload)
}


export {uploadSliderImages, fetchSliderImages, deleteSliderImage, updateSliderContent}

