<template>
  <div :style="imageStyle"
       class="background-banner"
  >
      <background-banner-text :title="imageTitle" :subtitle="imageDescription" />
  </div>
</template>

<script>
import {UPLOADS_FOLDER_URL} from '../../../../shared/constants/backend'
import BackgroundBannerText from '../background-banner-text/BackgroundBannerText'

export default {
  name: 'BackgroundBannerServerItem',
  components: {BackgroundBannerText},
  props: {
    imageName: {
      type: String,
      required: true
    },
    imageTitle: {
      type: String,
      required: true
    },
    imageDescription: {
      type: String,
      required: true
    }
  },
  computed: {
    imageStyle() {
      return {
        backgroundImage: `url('${`${UPLOADS_FOLDER_URL}${this.imageName}`}')`
      }
    }
  }
}
</script>
