<template>
  <el-carousel :indicator-position="indicatorPosition"
               :interval="swapDelay"
               :height="sliderHeight"
               trigger="click"
  >
    <el-carousel-item>
      <router-link :to="{name: $options.UNIT_ABOUT_ROUTE_NAME, params: {unitID: bgMainUnitId}}" custom>
        <div :style="{ 'background-image': `url(${bgImage})` }"
             class="background-banner">

                <background-banner-text :title="$t('page_main.title')"
                                        :subtitle="$t('page_main.subtitle')"
                />
        </div>
      </router-link>
    </el-carousel-item>
    <el-carousel-item v-for="img in sliderItems" :key="img.photoId">
      <template v-if="img.unitId === null">
        <background-banner-server-item :image-name="img.photo"
                                       :image-description="img.description"
                                       :image-title="img.title"
        />
      </template>
      <router-link v-else :to="{name: $options.UNIT_ABOUT_ROUTE_NAME, params: {unitID: img.unitId}}" custom>
        <background-banner-server-item :image-name="img.photo"
                                       :image-description="img.description"
                                       :image-title="img.title"
        />
      </router-link>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
import bgMain from '../../../../assets/images/bg-main-children.jpg'
import {fetchSliderImages} from '../../../admin/slider-manage/services/slider-manage'
import {UNIT_ABOUT_ROUTE_NAME} from '../../../shared/constants/app-routes-names'
import BackgroundBannerServerItem from './background-banner-server-item/BackgroundBannerServerItem'
import BackgroundBannerText from './background-banner-text/BackgroundBannerText'

export default {
  name: 'BackgroundBanner',
  components: {BackgroundBannerText, BackgroundBannerServerItem},
  inject: ['responsive'],
  UNIT_ABOUT_ROUTE_NAME,
  data() {
    return {
      sliderItems: [],
      bgMainUnitId: '46a49ea6-1c19-4f3b-a96c-8b0541e69113',
      swapDelay: 3000,
    }
  },
  mounted() {
    this.fetchImages()
  },
  methods: {
    fetchImages() {
      fetchSliderImages()
          .then((res) => {
            const ONE_SECOND = 1000
            const {durationTime, photos} = res.data
            this.sliderItems = photos
            this.swapDelay = durationTime * ONE_SECOND
          })
          .catch((e) => {
            console.error(e)
          })
    },

  },
  computed: {
    isMobile() {
      return this.responsive.isSm
    },
    isTablet() {
      return this.responsive.isMd
    },
    indicatorPosition() {
      return this.isMobile ? 'none' : 'outside'
    },
    sliderHeight() {
      const SLIDER_HEIGHT = {
        mobile: '300px',
        tablet: '500px',
        default: '700px'
      }

      if (this.isMobile) {
        return SLIDER_HEIGHT.mobile
      }
      if (this.isTablet) {
        return SLIDER_HEIGHT.tablet
      }

      return SLIDER_HEIGHT.default
    },
    bgImage() {
      return bgMain
    }
  }
}
</script>

<style scoped lang="scss">
.background-banner {
  background: no-repeat center center/cover;
  text-align: left;
  height: 100%;
  position: relative;
}
.background-banner:before {
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  height: 100%;
  width: 100%;
  z-index: 1;
  background: rgb(2,0,36);
  background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(9,9,121,0) 0%, rgba(0,0,0,0.38559173669467783) 100%);
}
</style>
