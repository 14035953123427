<template>
  <div class="app-container">
    <el-row>
      <el-col :span="24" :lg="14" :xl="16" v-if="!hideText">
        <h1 class="main-title main-title--title">{{ title }}</h1>
        <h2 class="main-title main-title--subtitle">{{ subtitle }}</h2>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'BackgroundBannerText',
  props: {
    title: {
      type: String,
      required: false
    },
    subtitle: {
      type: String,
      required: false
    },
  },
  computed: {
    hideText() {
      return !this.title && !this.subtitle
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/grid-mixins";

.main-title {
  color: #ffffff;
  border-left: 3px solid;
  padding: 0.5rem 0 0.5rem 2rem;
  position: relative;
  z-index: 2;
  &--title {
    font-weight: 700;
    font-size: 4.3em;
    margin-bottom: 0;
    border-color: #d7020a;
    margin-top: 3.5em;
    @include laptop {
      font-size: 3.5em;
      margin-top: 4em;
    }
    @include smartphone {
      font-size: 2.5em;
      margin-top: 2em;
    }
    @include xs-smartphone {
      font-size: 1.7em;
      margin-top: 2em;
    }
  }

  &--subtitle {
    font-weight: 600;
    margin-top: 0;
    font-size: 1.6em;
    font-style: italic;
    border-color: #0d3d88;
    @include laptop {
      font-size: 1.2em;
    }
    @include smartphone {
      font-size: 1em;
    }
    @include xs-smartphone {
      font-size: 1em;
    }
  }
}
</style>
