<template>
  <el-row v-loading.body="isLoading"  type="flex" :gutter="24">
    <el-col>
      <h3 class="heading page-heading">{{ $t('unit_list.title') }}</h3>
    </el-col>
    <el-col class="grid-content"
            v-for="unit in units"
            :key="unit.unitId"
            :xs="24"
            :sm="12"
            :md="12"
            :lg="8"
    >
      <UnitItem :unit="unit" />
    </el-col>
  </el-row>
</template>

<script>
import UnitItem from '../unit-item/UnitItem'
import {mapState} from 'vuex'

export default {
  name: 'UnitList',
  components: {
    UnitItem
  },
  props: {
    units: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState('loadingState', {
      isLoading: state => state.isLoading
    })
  },
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/grid-mixins";

.page-heading {
  margin: 0 0 1em;
  @include smartphone {
    margin-bottom: 0.7em;
  }
  @include xs-smartphone {
    margin-bottom: 0.7em;
  }
}
</style>
