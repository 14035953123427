<template>
  <div>
    <BackgroundBanner />
    <el-main>
      <div class="app-container">
        <UnitList :units="units" />
        <VScrollPagination
            @show-more="fetchUnitList"
            :total-count="totalCount"
            :initial-page="initialPage"
            :items-per-page="$options.DEFAULT_ITEMS_PER_PAGE"
        />
      </div>
    </el-main>
  </div>
</template>

<script>
import UnitList from './components/unit-list/UnitList'
import {fetchUnitActiveList} from '../unit-page/services/unit-sevice'
import BackgroundBanner from '../../layouts/components/background-banner/BackgroundBanner'
import VScrollPagination from '../../components/ui/v-scroll-pagination/VScrollPagination'

const DEFAULT_ITEMS_PER_PAGE = 6

export default {
  name: 'HomePage',
  DEFAULT_ITEMS_PER_PAGE,
  components: {VScrollPagination, BackgroundBanner, UnitList},
  data() {
    return {
      units: [],
      totalCount: 0,
      initialPage: 1
    }
  },
  created() {
    this.initialPage = Number(this.$route.query.page)
    this.fetchUnitList(this.$route.query.page)
  },
  methods: {
    async fetchUnitList(page = 1) {
      const {units, totalCount} = await fetchUnitActiveList(page, DEFAULT_ITEMS_PER_PAGE).catch((error) => {
        this.$message({
          showClose: true,
          message: error?.data,
          type: 'error'
        })
      })
      this.units = units
      this.totalCount = totalCount
      await this.$router.push({name: 'home', query: {page}})
    }
  }
}
</script>


