<template>
  <div class="unit-card-wrapper" @click="goToUnitPage">
    <el-card shadow="never" :body-style="$options.bodyStyle">
      <div class="image-wrapper">
        <img
            alt=""
            class="unit-image"
            :src="photoUrl"
        />
      </div>
      <div class="unit-info">
        <div>
          <h3 class="heading heading--unit-title">{{ unit.title }}</h3>
          <p class="unit-description">
            {{ unit.description }}
          </p>
        </div>
        <UnitProgress :unit-progress="unit.progressData" :unit-type="unit.unitType"/>
      </div>
    </el-card>
  </div>
</template>

<script>
import UnitProgress from '../../../components/units/unit-progress/UnitProgress'
import {UPLOADS_FOLDER_URL} from '../../../../shared/constants/backend'
import {UNIT_ABOUT_ROUTE_NAME} from '../../../../shared/constants/app-routes-names'

export default {
  name: 'UnitItem',
  components: {UnitProgress},
  bodyStyle: {
    padding: 0
  },
  props: {
    unit: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      photoUrl: null
    }
  },
  created() {
    this.generatePhotoUrl()
  },
  methods: {
    generatePhotoUrl() {
      this.photoUrl = `${UPLOADS_FOLDER_URL}${this.unit.photo}`
    },
    goToUnitPage() {
      this.$router.push({
        name: UNIT_ABOUT_ROUTE_NAME,
        params: {
          unitID: this.unit.unitId
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

.heading {
  &--unit-title {
    font-size: 1.3em;
    margin-bottom: 0.5em;
  }
  &--unit-statistic {
    font-size: 1.3em;
    margin-bottom: 0;
  }
}

.unit-card-wrapper {
  margin-bottom: 1.7em;
  &:hover {
    cursor: pointer;
  }
}

.unit-description {
  color: #606266;
  margin-top: 0;
  font-size: 13px;
  min-height: 120px;
}

.unit-info {
  padding: 1.7em;
}

.image-wrapper {
  position: relative;
  padding: 0 0 72% 0;
}
.unit-image {
  position: absolute;
  object-fit: cover;
  object-position: center;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
</style>
